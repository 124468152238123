.measurement-input-parent {
    @apply relative flex items-center w-full h-10;
    background-color: var(--loglines-white);
}
.measurement-input-parent label {
    @apply w-fit absolute;
    @apply translate-x-2;
    padding-right: 2px;
    background-color: var(--loglines-white);
}
.measurement-input-parent p {
    @apply w-fit;
    @apply text-size-XS;
    @apply -translate-y-2;
    padding-left: 3px;
    color: var(--loglines-gray-v5);
}

.measurement-input-parent .unit {
    @apply relative border border-l-0 rounded-r p-2 flex items-center justify-between w-20;
    height: inherit;
}

.measurement-input-parent .dropdown-list {
    @apply p-0 m-0 border absolute w-full z-10 rounded top-full left-0 max-h-[123px];
    border-color: var(--loglines-brand-v5);
}

.measurement-input-parent input {
    @apply p-2 flex-grow border border-r-0 rounded-l w-full;
    border-color: var(--loglines-gray-v3);
    height: inherit;
}

/* Chrome, Safari, Edge, Opera */
.measurement-input-parent input[type='number']::-webkit-outer-spin-button,
.measurement-input-parent input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.measurement-input-parent input[type='number'] {
    -moz-appearance: textfield;
}
