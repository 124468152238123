.user-access .header-status {
    @apply border-b border-solid border-gray-500;
}

.user-detail .content-1 p {
    @apply text-size-S;
}

.user-detail .content-1 h6 {
    @apply text-size-M font-light;
}

.user-detail .content-1 .edit-user {
    @apply mt-11;
}
