.user-detail {
    @apply overflow-auto flex sm:flex-col gap-3 h-full;

    .content-1 {
        @apply flex;
    }

    .content-1-A {
        @apply flex-initial !min-w-[400px] sm:!min-w-[0px] sm:w-[calc(100vw-1rem)];
        @apply p-4 flex flex-col;
    }
    .content-1-B {
        @apply p-4 overflow-auto;
    }

    .content-2 {
        @apply flex-1 p-4;
    }
}
