.button-JPL {
    background-color: var(--loglines-white);
    @apply absolute inset-y-0 right-0 text-size-XL rounded-[4px] w-[40px] justify-items-center;
}

.icon-JPL {
    color: var(--loglines-brand-v5);
    @apply align-middle justify-center mx-auto;
}

.button-IFB {
    border-color: var(--loglines-gray-v3);
    background-color: var(--loglines-white);
    @apply mr-[1px] absolute inset-y-0 right-0 border-l text-size-XL w-[40px] h-[28px] my-[6px] justify-items-center;
}

.icon-IFB {
    color: var(--loglines-brand-v5);
    @apply align-middle justify-center mx-auto;
}
