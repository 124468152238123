.simple-table {
    @apply w-full table-auto;
    thead th {
        position: sticky;
        top: 0;
    }
}

.simple-table thead tr {
    @apply border-b;
    border-color: var(--loglines-gray-v3);
}

.simple-table th {
    @apply text-left h-10 px-2 z-[1];
    color: var(--loglines-gray-v5);
    font-weight: 350;
}
.simple-table th .row-container {
    margin-top: 4px;
    width: max-content;
}

.simple-table tbody tr {
    border-color: var(--loglines-gray-v3);
    @apply border-b;
}

.simple-table tbody tr:hover {
    td {
        background-color: var(--loglines-gray-v2);
    }
}

.simple-table td:first-child,
.simple-table th:first-child {
    @apply pl-4;
}
.simple-table td {
    @apply text-left h-10 px-2;
}

.simple-table td .row-container {
    margin-top: 4px;
}
.simple-table td div {
    color: var(--loglines-gray-v6);
}

.simple-table-container {
    background-color: var(--loglines-white);
    border-color: var(--loglines-gray-v3);
    @apply relative h-full w-full border-solid;
}

.variant-gray {
    background-color: var(--loglines-gray-v1);
}
.variant-gray th {
    background-color: var(--loglines-gray-v2) !important;
}

.variant-gray td {
    background-color: var(--loglines-white);
}

.variant-gray .active {
    background-color: var(--loglines-brand-v2);
}

.variant-gray tbody tr:hover {
    td {
        background-color: var(--loglines-brand-v1);
    }
}
