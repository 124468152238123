.reset-password-bg {
    /* background-image: url('../../assets/img/generic/BgLogin.png'); */
    background-image: linear-gradient(
            rgba(0, 117, 161, 0.4),
            rgba(0, 117, 161, 0.4)
        ),
        url('../../../assets/img/gallery/BgLogin.png');

    /* Full height */
    height: 100vh;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.reset-password-container {
    background: #fff;
}

.reset-password-logo {
    margin-bottom: 20px;
    width: 160px;
}
