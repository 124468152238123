/* progress bar default */
.progress-bar {
    @apply flex items-center;
}

.progress-bar .bg-bar {
    background-color: var(--loglines-yellow-v2);
}

.progress-bar .bg-progress {
    background-color: var(--loglines-brand-v5);
}

.progress-bar i {
    color: var(--loglines-white);
}

/* progress-bar-outline-blue */
.progress-bar-outline-blue .border-bar {
    background-color: var(--loglines-white);
    @apply border-0;
}
