.logistical-layout {
    @apply w-full fixed sm:w-full;
    background-color: var(--loglines-gray-v1);
}
.logistical-content {
    /* height */
    @apply h-[calc(100vh-4rem)] overflow-y-auto;
    /* scrollbar */

    /* scrollbar-width: thin; */
    /* scrollbar-color: transparent transparent; */

    &::-webkit-scrollbar {
        width: 0px !important;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
    }
}

.mobile-version {
    @apply sm:absolute md:relative sm:left-0 sm:top-[64px];
}

.mobile-header-additional {
    @apply md:hidden lg:hidden;
}

.content-container {
    /* size */
    @apply relative;

    /* animation */
    @apply transition-all ease-in-out duration-[0.5s];

    /* margin */
    @apply m-5 sm:m-2;
}

.logo-animation.hide {
    overflow: hidden; /* Hide the element content, while height = 0 */
    height: 0;
    opacity: 0;
    position: absolute;
    transition:
        height 0ms 100ms,
        opacity 100ms 0ms;
}
.logo-animation {
    height: 40px; /* any measurable value, not "auto" */
    opacity: 1;
    transition:
        height 0ms 0ms,
        opacity 400ms 40ms;
}

.logo-small-animation.hide {
    overflow: hidden; /* Hide the element content, while height = 0 */
    height: 0;
    opacity: 0;
    transition:
        height 0ms 100ms,
        opacity 100ms 0ms;
}
.logo-small-animation {
    height: 40px; /* any measurable value, not "auto" */
    opacity: 1;
    transition:
        height 0ms 0ms,
        opacity 700ms 0ms;
}

.text-animation.hide {
    overflow: hidden; /* Hide the element content, while height = 0 */
    height: 0;
    opacity: 0;
    width: 0;
    transition:
        height 0ms 100ms,
        opacity 0ms 0ms;
}
.text-animation {
    opacity: 1;
    transition:
        height 0ms 0ms,
        opacity 200ms 0ms;
}

.filter {
    @apply w-[35%] lg:w-[40%] xl:w-[35%] md:w-[60%] sm:w-[90%];
}
