.inputParent-style {
    @apply w-full flex relative;
}

.inputParent-style .input-group {
    @apply border rounded;
}
.inputParent-style .input-group input {
    @apply flex-1  border-none;
}

.inputParent-style label {
    @apply -translate-y-2 w-fit bg-white ml-2 z-10 text-size-XS absolute;
}

.inputParent-style input {
    @apply h-10;
    @apply border !rounded-logistical-radius text-size-S;
    @apply focus:!ring-transparent;
    @apply !w-full;
    border-color: var(--loglines-gray-v3);
    padding-top: 10px;
}
.inputParent-style input:focus {
    border-color: var(--loglines-brand-v5);
}
.inputParent-style input::placeholder {
    color: var(--loglines-gray-v4);
}

.inputParent-style input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px white inset;
    -webkit-text-fill-color: #333;
}

.inputParent-style input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px white inset;
    -webkit-text-fill-color: #333;
}

.disabled {
    pointer-events: none;
    background-color: var(--loglines-gray-v1);
}
