.error-container {
    @apply h-full flex flex-col justify-center items-center;
    background-color: var(--loglines-white);
}

.table-container {
    @apply relative overflow-auto w-full;

    /* height */
    /* @apply h-[calc(100vh-230px)]; */
}

.table-component .dropdown label {
    @apply text-size-M;
}

.table-row {
    @apply relative my-2 overflow-auto w-max h-[calc(100vh-170px)] rounded;
    background-color: var(--loglines-white);
}

.table-master {
    @apply w-full;
}

.thead-master {
    @apply w-[calc(100vw-1em)] h-10  bg-gray-50;
}

.thead-master .tr-master {
    @apply border-b;
}

.th-master:first-child,
.td-master:first-child {
    @apply pl-5;
}
.td-master {
    color: var(--loglines-gray-v6) !important;
    @apply px-2 py-2 whitespace-nowrap;
}
.td-master div {
    @apply h-5 flex flex-row;
}

.tr-master {
    @apply border-b transition;
    background-color: var(--loglines-white);
    border-color: var(--loglines-gray-v3);
}
.tr-master:hover {
    .td-master {
        background-color: var(--loglines-gray-v2);
    }
}

.tr-selected {
    .td-master {
        background-color: var(--loglines-brand-v2);
    }
}
.tr-selected:hover {
    .td-master {
        background-color: var(--loglines-brand-v3);
    }
}

.th-master {
    @apply text-left px-2 my-4 sticky top-0 whitespace-nowrap;
    background-color: var(--loglines-white);
    font-weight: 350;
}

.th-master div {
    color: var(--loglines-gray-v5) !important;
    @apply h-5 flex flex-row;
}

.edit-rename-column {
    @apply right-0 text-right;
}

.sort-button {
    @apply flex flex-col  border-solid border-2 rounded-logistical-radius-2 ml-1 w-5 h-5;
}

.sort-button i {
    color: var(--loglines-gray-v5);
    @apply aspect-square text-size-M -translate-x-1 -translate-y-1;
}

.sort-active {
    color: var(--loglines-gray-v5);
    i {
        color: var(--loglines-gray-v5);
    }
}

/* checkbox sticky */
.sticky-column {
    position: sticky;
    left: 0;
    z-index: 1;
}
