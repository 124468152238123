:root {
    /* ------ Basic Loglines Colors ------ */
    /* ------ Basic Main Gray ------ */
    --loglines-gray-v1: #f5f6f7;
    --loglines-gray-v2: #f0f1f3;
    --loglines-gray-v3: #d9dde1;
    --loglines-gray-v4: #c0c7cd;
    --loglines-gray-v5: #8d99a5;
    --loglines-gray-v6: #5b6c7c;
    --loglines-gray-v7: #465a6c;
    --loglines-gray-v8: #283f54;
    --loglines-gray-v9: #20384e;

    /* ------ Basic Main Status ------ */
    --loglines-red-v1: #fcdae2;
    --loglines-red-v2: #f691a9;
    --loglines-red-v3: #f04770;
    --loglines-red-v4: #902b43;
    --loglines-yellow-v1: #fff6e1;
    --loglines-yellow-v2: #ffe3a4;
    --loglines-yellow-v3: #ffd167;
    --loglines-yellow-v4: #b28f3e;
    --loglines-green-v1: #cdf7ec;
    --loglines-green-v2: #6fdfc1;
    --loglines-green-v3: #10d3a0;
    --loglines-green-v4: #048160;

    /* ------ Loglines Extra ------ */
    --loglines-extra-red-v1: #fcdae2;
    --loglines-extra-red-v2: #f04770;
    --loglines-extra-red-v3: #fce4da;
    --loglines-extra-red-v4: #f07a47;
    --loglines-extra-yellow-v1: #fff6e1;
    --loglines-extra-yellow-v2: #ffd167;
    --loglines-extra-yellow-v3: #fff7c5;
    --loglines-extra-yellow-v4: #fbe551;
    --loglines-extra-green-v1: #e5fcda;
    --loglines-extra-green-v2: #7df047;
    --loglines-extra-green-v3: #cdf7ec;
    --loglines-extra-green-v4: #06d7a0;
    --loglines-extra-blue-v1: #cdf4f7;
    --loglines-extra-blue-v2: #06cad7;
    --loglines-extra-blue-v3: #dadefc;
    --loglines-extra-blue-v4: #4758f0;
    --loglines-extra-violet-v1: #eddafc;
    --loglines-extra-violet-v2: #a647f0;
    --loglines-extra-violet-v3: #fcdaf5;
    --loglines-extra-violet-v4: #f047cb;

    /* ------ Loglines Support Color ------ */
    --loglines-white: #ffffff;
    --loglines-black: #222222;

    /* Bg Color */
    .themes-bg-gray-v1 {
        background-color: var(--loglines-gray-v1);
    }
    .themes-bg-gray-v2 {
        background-color: var(--loglines-gray-v2);
    }
    .themes-bg-gray-v3 {
        background-color: var(--loglines-gray-v3);
    }
    .themes-bg-gray-v4 {
        background-color: var(--loglines-gray-v4);
    }
    .themes-bg-gray-v5 {
        background-color: var(--loglines-gray-v5);
    }
    .themes-bg-gray-v6 {
        background-color: var(--loglines-gray-v6);
    }
    .themes-bg-gray-v7 {
        background-color: var(--loglines-gray-v7);
    }
    .themes-bg-gray-v8 {
        background-color: var(--loglines-gray-v8);
    }
    .themes-bg-gray-v9 {
        background-color: var(--loglines-gray-v9);
    }
    .themes-bg-red-v1 {
        background-color: var(--loglines-red-v1);
    }
    .themes-bg-red-v2 {
        background-color: var(--loglines-red-v2);
    }
    .themes-bg-red-v3 {
        background-color: var(--loglines-red-v3);
    }
    .themes-bg-red-v4 {
        background-color: var(--loglines-red-v4);
    }
    .themes-bg-yellow-v1 {
        background-color: var(--loglines-yellow-v1);
    }
    .themes-bg-yellow-v2 {
        background-color: var(--loglines-yellow-v2);
    }
    .themes-bg-yellow-v3 {
        background-color: var(--loglines-yellow-v3);
    }
    .themes-bg-yellow-v4 {
        background-color: var(--loglines-yellow-v4);
    }
    .themes-bg-green-v1 {
        background-color: var(--loglines-green-v1);
    }
    .themes-bg-green-v2 {
        background-color: var(--loglines-green-v2);
    }
    .themes-bg-green-v3 {
        background-color: var(--loglines-green-v3);
    }
    .themes-bg-green-v4 {
        background-color: var(--loglines-green-v4);
    }
    .themes-bg-extra-blue-v2 {
        background-color: var(--loglines-extra-blue-v2);
    }
    .themes-bg-extra-red-v4 {
        background-color: var(--loglines-extra-red-v4);
    }
    .themes-bg-extra-violet-v2 {
        background-color: var(--loglines-extra-violet-v2);
    }

    /* Hover Bg Color */
    .themes-bg-hover-gray-v1:hover {
        background-color: var(--loglines-gray-v1);
    }
    .themes-bg-hover-gray-v2:hover {
        background-color: var(--loglines-gray-v2);
    }
    .themes-bg-hover-gray-v3:hover {
        background-color: var(--loglines-gray-v3);
    }
    .themes-bg-hover-gray-v4:hover {
        background-color: var(--loglines-gray-v4);
    }
    .themes-bg-hover-gray-v5:hover {
        background-color: var(--loglines-gray-v5);
    }
    .themes-bg-hover-gray-v6:hover {
        background-color: var(--loglines-gray-v6);
    }
    .themes-bg-hover-gray-v7:hover {
        background-color: var(--loglines-gray-v7);
    }
    .themes-bg-hover-gray-v8:hover {
        background-color: var(--loglines-gray-v8);
    }
    .themes-bg-hover-gray-v9:hover {
        background-color: var(--loglines-gray-v9);
    }
    .themes-bg-hover-red-v1:hover {
        background-color: var(--loglines-red-v1);
    }
    .themes-bg-hover-red-v2:hover {
        background-color: var(--loglines-red-v2);
    }
    .themes-bg-hover-red-v3:hover {
        background-color: var(--loglines-red-v3);
    }
    .themes-bg-hover-red-v4:hover {
        background-color: var(--loglines-red-v4);
    }
    .themes-bg-hover-yellow-v1:hover {
        background-color: var(--loglines-yellow-v1);
    }
    .themes-bg-hover-yellow-v2:hover {
        background-color: var(--loglines-yellow-v2);
    }
    .themes-bg-hover-yellow-v3:hover {
        background-color: var(--loglines-yellow-v3);
    }
    .themes-bg-hover-yellow-v4:hover {
        background-color: var(--loglines-yellow-v4);
    }
    .themes-bg-hover-green-v1:hover {
        background-color: var(--loglines-green-v1);
    }
    .themes-bg-hover-green-v2:hover {
        background-color: var(--loglines-green-v2);
    }
    .themes-bg-hover-green-v3:hover {
        background-color: var(--loglines-green-v3);
    }
    .themes-bg-hover-green-v4:hover {
        background-color: var(--loglines-green-v4);
    }

    /* Text Color */
    .themes-text-gray-v1 {
        color: var(--loglines-gray-v1);
    }
    .themes-text-gray-v2 {
        color: var(--loglines-gray-v2);
    }
    .themes-text-gray-v3 {
        color: var(--loglines-gray-v3);
    }
    .themes-text-gray-v4 {
        color: var(--loglines-gray-v4);
    }
    .themes-text-gray-v5 {
        color: var(--loglines-gray-v5);
    }
    .themes-text-gray-v6 {
        color: var(--loglines-gray-v6);
    }
    .themes-text-gray-v7 {
        color: var(--loglines-gray-v7);
    }
    .themes-text-gray-v8 {
        color: var(--loglines-gray-v8);
    }
    .themes-text-gray-v9 {
        color: var(--loglines-gray-v9);
    }
    .themes-text-red-v1 {
        color: var(--loglines-red-v1);
    }
    .themes-text-red-v2 {
        color: var(--loglines-red-v2);
    }
    .themes-text-red-v3 {
        color: var(--loglines-red-v3);
    }
    .themes-text-red-v4 {
        color: var(--loglines-red-v4);
    }
    .themes-text-yellow-v1 {
        color: var(--loglines-yellow-v1);
    }
    .themes-text-yellow-v2 {
        color: var(--loglines-yellow-v2);
    }
    .themes-text-yellow-v3 {
        color: var(--loglines-yellow-v3);
    }
    .themes-text-yellow-v4 {
        color: var(--loglines-yellow-v4);
    }
    .themes-text-green-v1 {
        color: var(--loglines-green-v1);
    }
    .themes-text-green-v2 {
        color: var(--loglines-green-v2);
    }
    .themes-text-green-v3 {
        color: var(--loglines-green-v3);
    }
    .themes-text-green-v4 {
        color: var(--loglines-green-v4);
    }
    .themes-text-white {
        color: var(--loglines-white);
    }
    .themes-text-black {
        color: var(--loglines-black);
    }

    /* Global Status */
    .themes-status-green {
        color: var(--loglines-gray-v4);
    }
    .themes-status-green span {
        background-color: var(--loglines-green-v1);
        color: var(--loglines-green-v4);
    }
    .themes-status-green:hover {
        color: var(--loglines-green-v4);
        span {
            background-color: var(--loglines-green-v1);
            color: var(--loglines-green-v4);
        }
    }
    .themes-status-green.active,
    .themes-status-green.active:hover {
        color: var(--loglines-green-v4);
    }
    .themes-status-green.active span,
    .themes-status-green.active span:hover {
        /* make active not impact   */
        background-color: var(--loglines-green-v3);
        color: var(--loglines-white);
    }
    .themes-status-green.active .bottom-line {
        background-color: var(--loglines-green-v3);
    }

    /* status yellow */
    .themes-status-yellow {
        color: var(--loglines-gray-v4);
    }
    .themes-status-yellow span {
        background-color: var(--loglines-yellow-v1);
        color: var(--loglines-yellow-v4);
    }
    .themes-status-yellow:hover {
        color: var(--loglines-yellow-v4);
        span {
            background-color: var(--loglines-yellow-v1);
            color: var(--loglines-yellow-v4);
        }
    }
    .themes-status-yellow.active,
    .themes-status-yellow.active:hover {
        color: var(--loglines-yellow-v4);
    }
    .themes-status-yellow.active span,
    .themes-status-yellow.active span:hover {
        /* make active not impact   */
        background-color: var(--loglines-yellow-v3);
        color: var(--loglines-yellow-v4);
    }
    .themes-status-yellow.active .bottom-line {
        background-color: var(--loglines-yellow-v3);
    }

    /* status red */
    .themes-status-red {
        color: var(--loglines-gray-v4);
    }
    .themes-status-red span {
        background-color: var(--loglines-red-v1);
        color: var(--loglines-red-v4);
    }
    .themes-status-red:hover {
        color: var(--loglines-red-v4);
        span {
            background-color: var(--loglines-red-v1);
            color: var(--loglines-red-v4);
        }
    }
    .themes-status-red.active,
    .themes-status-red.active:hover {
        color: var(--loglines-red-v4);
    }
    .themes-status-red.active span,
    .themes-status-red.active span:hover {
        /* make active not impact   */
        background-color: var(--loglines-red-v3);
        color: var(--loglines-white);
    }
    .themes-status-red.active .bottom-line {
        background-color: var(--loglines-red-v3);
    }

    /* status gray */
    .themes-status-gray {
        color: var(--loglines-gray-v4);
    }
    .themes-status-gray span {
        background-color: var(--loglines-gray-v2);
        color: var(--loglines-gray-v6);
    }
    .themes-status-gray:hover {
        color: var(--loglines-gray-v6);
        span {
            background-color: var(--loglines-gray-v2);
            color: var(--loglines-gray-v6);
        }
    }
    .themes-status-gray.active,
    .themes-status-gray.active:hover {
        color: var(--loglines-gray-v6);
    }
    .themes-status-gray.active span,
    .themes-status-gray.active span:hover {
        /* make active not impact   */
        background-color: var(--loglines-gray-v4);
        color: var(--loglines-gray-v6);
    }
    .themes-status-gray.active .bottom-line {
        background-color: var(--loglines-gray-v4);
    }

    /* Custom Global Class goes here */
    .themes-border-color {
        border-color: var(--loglines-gray-v3);
    }
    .themes-border-red {
        border-color: var(--loglines-red-v3);
    }
    .themes-border-extra-red-v4 {
        border-color: var(--loglines-extra-red-v4);
    }
    .themes-bg-white {
        background-color: var(--loglines-white);
    }
    .themes-bg-black {
        background-color: var(--loglines-black);
    }
    .themes-text-black {
        color: var(--loglines-black);
    }
    .themes-text-white {
        color: var(--loglines-white);
    }
}
